<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.logs')"
                :pgIcon="'bx bx-brush-alt'"
                :refs="refs"
                :addNew=true>
            </Breadcrumb>


        </div>
    </div>

</template>

<script>
export default {
    name: 'List',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
    },
    data(){
        return {    
            //
            refs: 'categories',
        }
    },
    mounted() {},
    created() {},
    methods: {
        //
    },
}
</script>
